
	export default {
		name: "navigation",
		data() {
			return {
				searchFocus: false,
				searchInput: "",
			};
		},
		computed: {
			mobileNav() {
				return this.$store.state.mobileNav;
			},
			logged() {
				return this.$store.state.logged;
			},
			user() {
				return this.$store.state.user;
			},
		},
	};
