
import searchContainer from "@/components/search-container.vue";
	import navigation from "@/components/nav.vue";
	import footerBar from "@/components/footer.vue";
	export default {
		components: {
			navigation,
			footerBar,
			searchContainer
		},
		data() {
			return {
				adminNav: false,
				dropdown: false,
				dragState: false,
				footerBar: true,
			};
		},
		watch: {
			$route(to, from) {
				if (to.path == "/admin/profil") {
					this.dropdown = true;
				} else {
					this.dropdown = false;
				}
			},
		},
		mounted(){
			if(this.$store.state.logged == true){
				this.$axios.get('/users/@self')
				.then((response) => {
					if(response.status == 200){
					this.$store.commit('SET_USER', response.data)
					}
				})
				.catch((error) =>{
					if(error.response.status == 401 || error.response.status == 403){
						this.$store.commit('SET_LOGGED', false)
						this.$store.commit('SET_USER', undefined)
					}
				})
			}
		},
		computed: {
			logged() {
				return this.$store.state.logged;
			},
			mobileNav() {
				return this.$store.state.mobileNav;
			},
		},
		methods: {
			logout() {
				$axios
					.$delete("/token")
					.then((response) => {
						this.$store.commit("SET_LOGGED", false);
						this.$store.commit("SET_USER", undefined);
					})
					.catch((error) => {});
			},
		},
	};
